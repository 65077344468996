"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsPaProphyZuordnungColumnsDefs = void 0;
const types_1 = require("@rose/types");
const editSettingsStore_1 = require("@/state/settings/editSettingsStore");
function settingsPaProphyZuordnungColumnsDefs() {
    return [
        {
            headerName: 'Terminart',
            valueGetter: params => {
                var _a;
                if (params.data) {
                    const terminart = (_a = editSettingsStore_1.editSettingsStore.getters.terminartenMap) === null || _a === void 0 ? void 0 : _a[params.data];
                    return `${terminart === null || terminart === void 0 ? void 0 : terminart.bezeichnung} (${terminart === null || terminart === void 0 ? void 0 : terminart.extid})`;
                }
            },
            sort: 'asc',
            sortable: true,
            width: 350,
            resizable: true,
        },
        {
            headerName: 'Termingruppe',
            valueGetter: params => { var _a, _b; return params.data && ((_b = (_a = editSettingsStore_1.editSettingsStore.getters.terminartenMap) === null || _a === void 0 ? void 0 : _a[params.data]) === null || _b === void 0 ? void 0 : _b.gruppe); },
            field: 'gruppe',
            cellRenderer: 'TermingruppeCell',
            sortable: true,
            width: 200,
            resizable: true,
        },
        {
            headerName: 'PAR-Art',
            flex: 1,
            field: 'paTyp',
            valueGetter: params => { var _a; return params.data && { key: params.data, value: (_a = editSettingsStore_1.editSettingsStore.getters.terminartenConfiguredPa) === null || _a === void 0 ? void 0 : _a[params.data] }; },
            cellRenderer: 'ToggleButtonsCell',
            cellRendererParams: {
                context: {
                    buttons: [
                        {
                            value: types_1.PAType.PZR,
                            name: 'Prophylaxe',
                            help: 'Termine in denen Prophylaxeleistungen im Sinne von Zahnreinigung und Biofilm-Management erbracht werden (keine Versiegelung etc.).',
                            outlined: true,
                        },
                        {
                            value: types_1.PAType.PAV,
                            name: 'PAV',
                            help: 'Termine, die im Rahmen des PAR-Konzepts der Parodontitis Vorbehandlung dienen.',
                            outlined: true,
                        },
                        {
                            value: types_1.PAType.PA,
                            name: 'PAR',
                            help: 'Termine in denen die eigentliche antiinfektiöse Therapie (Scaling- & Root-Planing) stattfindet.',
                            outlined: true,
                        },
                        {
                            value: types_1.PAType.PAN,
                            name: 'PAN',
                            help: 'Termine, in denen die Befundevaluation / PA-Nachsorge stattfindet. Bei Kombiterminen BEV/UPT bitte als UPT markieren.',
                            outlined: true,
                        },
                        {
                            value: types_1.PAType.UPT,
                            name: 'BEV/UPT',
                            help: 'Termine nach abgeschlossener PA-Behandlung zur unterstützenden Parodontitis Therapie. Bei Kombiterminen BEV/UPT bitte als UPT markieren.',
                            outlined: true,
                        },
                        { value: types_1.PAType.OHNE, name: 'Nicht vergeben', color: 'error', outlined: true },
                    ],
                    clicked(field) {
                        editSettingsStore_1.editSettingsStore.commit.updateTerminArtPaArt({ terminart: field.key, paType: field.value });
                    },
                },
            },
            sortable: false,
            width: 400,
        },
        {
            headerName: '',
            cellRenderer: 'DeleteButtonCell',
            width: 40,
            cellStyle: { justifyContent: 'center' },
            cellRendererParams: {
                context: {
                    clicked(field) {
                        editSettingsStore_1.editSettingsStore.commit.deleteTerminartenPa([field.row]);
                    },
                },
            },
        },
    ];
}
exports.settingsPaProphyZuordnungColumnsDefs = settingsPaProphyZuordnungColumnsDefs;
