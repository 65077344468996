"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsHkpZuordnungColumnsDefs = void 0;
const types_1 = require("@rose/types");
const editSettingsStore_1 = require("@/state/settings/editSettingsStore");
function settingsHkpZuordnungColumnsDefs() {
    return [
        {
            headerName: 'Terminart',
            field: 'bezeichnung',
            valueGetter: params => {
                var _a;
                if (params.data) {
                    const terminart = (_a = editSettingsStore_1.editSettingsStore.getters.terminartenMap) === null || _a === void 0 ? void 0 : _a[params.data];
                    return `${terminart === null || terminart === void 0 ? void 0 : terminart.bezeichnung} (${terminart === null || terminart === void 0 ? void 0 : terminart.extid})`;
                }
            },
            sort: 'asc',
            sortable: true,
            width: 350,
            resizable: true,
        },
        {
            headerName: 'Termingruppe',
            valueGetter: params => { var _a; return params.data && ((_a = editSettingsStore_1.editSettingsStore.getters.terminartenMap) === null || _a === void 0 ? void 0 : _a[params.data].gruppe); },
            field: 'gruppe',
            cellRenderer: 'TermingruppeCell',
            sortable: true,
            width: 200,
            resizable: true,
        },
        {
            headerName: 'HKP-Art',
            flex: 1,
            field: 'hkpTyp',
            valueGetter: params => { var _a; return params.data && { key: params.data, value: (_a = editSettingsStore_1.editSettingsStore.getters.terminartenConfiguredHkp) === null || _a === void 0 ? void 0 : _a[params.data] }; },
            cellRenderer: 'ToggleButtonsCell',
            cellRendererParams: {
                context: {
                    buttons: [
                        {
                            value: types_1.HKPTerminType.ZE,
                            name: 'ZE',
                            help: 'Für diese Terminart muss ein genehmigter ZE-HKP/HKP vorliegen.',
                            outlined: true,
                        },
                        {
                            value: types_1.HKPTerminType.PA,
                            name: 'PAR',
                            help: 'Für diese Terminart muss ein genehmigter PAR-HKP/HKP vorliegen.',
                            outlined: true,
                        },
                        {
                            value: types_1.HKPTerminType.IMPLA,
                            name: 'IMPLA',
                            help: 'Für diese Terminart muss ein genehmigter Implantat HKP vorliegen.',
                            outlined: true,
                        },
                        {
                            value: types_1.HKPTerminType.PRIVAT,
                            name: 'Privat',
                            help: 'Für diese Terminart muss ein genehmigter privater HKP vorliegen.',
                            outlined: true,
                        },
                        {
                            value: types_1.HKPTerminType.KFO,
                            name: 'KFO',
                            help: 'Für diese Terminart muss ein genehmigter KFO HKP vorliegen.',
                            outlined: true,
                        },
                        {
                            value: types_1.HKPTerminType.KBR,
                            name: 'KBr',
                            help: 'Für diese Terminart muss ein genehmigter KBr HKP vorliegen. HINWEIS: Nicht alle KZV-Bereiche benötigen einen genehmigten KBr HKP!',
                            outlined: true,
                            color: 'amber',
                        },
                        {
                            value: types_1.HKPTerminType.ALLE,
                            name: 'Alle',
                            help: 'Für diese Terminart muss irgendein genehmigter HKP/HKP vorliegen.',
                            outlined: true,
                        },
                        { value: types_1.HKPTerminType.OHNE, name: 'Nicht vergeben', color: 'error', outlined: true },
                    ],
                    clicked(params) {
                        console.log('clicked', params);
                        editSettingsStore_1.editSettingsStore.commit.updateTerminArtHkpArt({ terminart: params.key, hkpType: params.value });
                    },
                },
            },
            sortable: false,
            width: 400,
        },
        {
            headerName: '',
            field: 'extid',
            cellRenderer: 'DeleteButtonCell',
            width: 40,
            cellStyle: { justifyContent: 'center' },
            cellRendererParams: {
                context: {
                    clicked(field) {
                        editSettingsStore_1.editSettingsStore.commit.deleteTerminartenHkp([field.row]);
                    },
                },
            },
        },
    ];
}
exports.settingsHkpZuordnungColumnsDefs = settingsHkpZuordnungColumnsDefs;
