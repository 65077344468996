"use strict";

var _toConsumableArray = require("/builds/rose-metrics/rose-metrics/vui/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/toConsumableArray.js")["default"];

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.array.index-of.js");

require("core-js/modules/es.array.splice.js");

require("core-js/modules/es.regexp.constructor.js");

require("core-js/modules/es.regexp.dot-all.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.regexp.sticky.js");

require("core-js/modules/es.regexp.to-string.js");

require("core-js/modules/es.string.match.js");

require("core-js/modules/web.dom-collections.iterator.js");

require("core-js/modules/es.regexp.test.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var common_ui_1 = require("@rose/common-ui");

var paProphyZuordnung_1 = require("@/components/columns/settings/paProphyZuordnung");

var enums_1 = require("@/types/enums");

var hkpZuordnung_1 = require("@/components/columns/settings/hkpZuordnung");

var types_1 = require("@rose/types");

var lodash_1 = require("lodash");

var staticDataMetricsStore_1 = require("@/state/staticDataMetricsStore");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

exports["default"] = (0, vue_1.defineComponent)({
  props: {
    zuordnungsart: {
      type: String,
      required: true
    }
  },
  data: function data() {
    return {
      localeText: common_ui_1.german,
      gridOptions: {},
      gridApi: {},
      gridColumnApi: {},
      columnDefs: {},
      rowClassRules: {},
      pendingZuordnungen: [],
      filter: ''
    };
  },
  computed: {
    terminartenNotConfigured: function terminartenNotConfigured() {
      if (this.zuordnungsart === enums_1.Zuordnungsart.PA) {
        return editSettingsStore_1.editSettingsStore.getters.terminartenNotConfiguredPA;
      }

      if (this.zuordnungsart === enums_1.Zuordnungsart.HKP) {
        return editSettingsStore_1.editSettingsStore.getters.terminartenNotConfiguredHKP;
      }

      return [];
    },
    terminartenConfigured: function terminartenConfigured() {
      if (this.zuordnungsart === enums_1.Zuordnungsart.PA && !this.filter) {
        return editSettingsStore_1.editSettingsStore.getters.terminartenConfiguredPaKeys;
      }

      if (this.zuordnungsart === enums_1.Zuordnungsart.HKP && !this.filter) {
        return editSettingsStore_1.editSettingsStore.getters.terminartenConfiguredHkpKeys;
      }

      if (this.zuordnungsart === enums_1.Zuordnungsart.PA && this.filter) {
        return this.filterTerminArt(enums_1.Zuordnungsart.PA);
      }

      if (this.zuordnungsart === enums_1.Zuordnungsart.HKP && this.filter) {
        return this.filterTerminArt(enums_1.Zuordnungsart.HKP);
      }

      return [];
    },
    autoConfiguredIds: function autoConfiguredIds() {
      if (this.zuordnungsart === enums_1.Zuordnungsart.PA) {
        return editSettingsStore_1.editSettingsStore.getters.terminartenAutoConfiguredPaIds;
      }

      if (this.zuordnungsart === enums_1.Zuordnungsart.HKP) {
        return editSettingsStore_1.editSettingsStore.getters.terminartenAutoConfiguredHkpIds;
      }

      return [];
    },
    autoConfiguredCount: function autoConfiguredCount() {
      if (this.zuordnungsart === enums_1.Zuordnungsart.PA) {
        return editSettingsStore_1.editSettingsStore.getters.terminartenAutoConfiguredPaCount;
      }

      if (this.zuordnungsart === enums_1.Zuordnungsart.HKP) {
        return editSettingsStore_1.editSettingsStore.getters.terminartenAutoConfiguredHkpCount;
      }

      return 0;
    },
    autoConfiguredText: function autoConfiguredText() {
      if (this.zuordnungsart === enums_1.Zuordnungsart.PA) {
        return "".concat(editSettingsStore_1.editSettingsStore.getters.terminartenAutoConfiguredPaCount, " PA-Terminart automatisch anlegen");
      }

      if (this.zuordnungsart === enums_1.Zuordnungsart.HKP) {
        return "".concat(editSettingsStore_1.editSettingsStore.getters.terminartenAutoConfiguredHkpCount, " HKP-Terminarten automatisch anlegen");
      }

      return '';
    }
  },
  beforeMount: function beforeMount() {
    if (this.zuordnungsart === 'pa') {
      this.columnDefs = (0, paProphyZuordnung_1.settingsPaProphyZuordnungColumnsDefs)();
    }

    if (this.zuordnungsart === 'hkp') {
      this.columnDefs = (0, hkpZuordnung_1.settingsHkpZuordnungColumnsDefs)();
    }

    this.gridOptions = {
      rowHeight: 40,
      defaultColDef: {
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        }
      },
      rowSelection: 'multiple',
      suppressRowHoverHighlight: true,
      suppressRowClickSelection: true,
      suppressCellFocus: true
    };
    this.rowClassRules = {
      'row-not-configured-even': function rowNotConfiguredEven(params) {
        var _a, _b;

        return params.node.rowIndex && params.node.rowIndex % 2 === 0 && (((_a = params.data) === null || _a === void 0 ? void 0 : _a.paTyp) === types_1.PAType.OHNE || ((_b = params.data) === null || _b === void 0 ? void 0 : _b.hkpTyp) === types_1.HKPTerminType.OHNE) || false;
      },
      'row-not-configured-odd': function rowNotConfiguredOdd(params) {
        var _a, _b;

        return params.node.rowIndex && params.node.rowIndex % 2 !== 0 && (((_a = params.data) === null || _a === void 0 ? void 0 : _a.paTyp) === types_1.PAType.OHNE || ((_b = params.data) === null || _b === void 0 ? void 0 : _b.hkpTyp) === types_1.HKPTerminType.OHNE) || false;
      }
    };
  },
  methods: {
    onGridReady: function onGridReady(event) {
      this.gridApi = event.api;
      this.gridColumnApi = event.columnApi;
    },
    onCellClicked: function onCellClicked(event) {
      this.$log.debug('Cell clicked', event);
    },
    removePendingZuordnung: function removePendingZuordnung(item) {
      var index = this.pendingZuordnungen.indexOf(item.extid);

      if (index >= 0) {
        this.pendingZuordnungen.splice(index, 1);
      }
    },
    commitPendingZuordnungen: function commitPendingZuordnungen() {
      if (this.zuordnungsart === enums_1.Zuordnungsart.PA) {
        console.log('commitPendingZuordnungen', this.pendingZuordnungen);
        editSettingsStore_1.editSettingsStore.commit.addTerminartenPa(this.pendingZuordnungen);
      }

      if (this.zuordnungsart === enums_1.Zuordnungsart.HKP) {
        editSettingsStore_1.editSettingsStore.commit.addTerminartenHkp(this.pendingZuordnungen);
      }

      this.pendingZuordnungen = [];
    },
    commitAutoZuordnung: function commitAutoZuordnung() {
      if (this.zuordnungsart === enums_1.Zuordnungsart.PA) {
        editSettingsStore_1.editSettingsStore.commit.addTerminartenPa(_toConsumableArray(this.autoConfiguredIds));
      }

      if (this.zuordnungsart === enums_1.Zuordnungsart.HKP) {
        editSettingsStore_1.editSettingsStore.commit.addTerminartenHkp(_toConsumableArray(this.autoConfiguredIds));
      }
    },
    terminArtFilter: function terminArtFilter(item, queryText) {
      var rgx = new RegExp(queryText, 'i');

      if (item.bezeichnung.match(rgx)) {
        return true;
      }

      return !!item.extid.match(rgx);
    },
    filterTerminArt: function filterTerminArt(type) {
      var rgx = new RegExp(this.filter, 'i');
      var terminarten = type === enums_1.Zuordnungsart.PA ? editSettingsStore_1.editSettingsStore.state.editSettings.terminArtZuPaType : editSettingsStore_1.editSettingsStore.state.editSettings.terminArtZuHkpType;
      return (0, lodash_1.chain)(staticDataMetricsStore_1.staticDataMetricsStore.state.terminarten).filter(function (ta) {
        return ta.extid in terminarten && (rgx.test(ta.bezeichnung) || rgx.test(ta.extid));
      }).keyBy('extid').keys().value();
    },
    getRowId: function getRowId(params) {
      return params.data;
    }
  }
});